.footerParallax {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer {
  background-color: #211e2b;
  padding: 75px 0;
  color: #fff;
  font-size: 1.25rem;
}

.footer a {
  -webkit-transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
  transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
  color: #fff;
}
.footer-left h3 {
  font-weight: 500;
}
.footer-left h3.line__yellow {
  position: relative;
  margin-bottom: 40px;
}

.footer-left h3.line__yellow::after {
  content: "";
  border-top: 0.3rem solid #fdc229;
  width: 8rem;

  position: absolute;
  bottom: -20px;
  left: 0;
}

.footer_quick_links li {
  line-height: 36px;
}

.footer a:hover {
  color: #fdc229;
}

.footer_social_icon span {
  width: 36px;
  background-color: #ffffff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
}

.disclaimer p {
  color: #e1dbdb;
  font-size: 1rem;
}

.footer-widget .container {
  display: flex;

  justify-content: space-between;
}

.footer__logo {
  max-width: 50px;
}

@media (max-width: 991px) {
  .footerParallax {
    position: static;
  }
}

@media (max-width: 767px) {
  .footer_social_icon {
    margin-bottom: 30px;
  }

  .footer-widget .container {
    display: block !important;
  }

  .footer_quick_links {
    margin-bottom: 30px;
  }
  .footer-left h3 br {
    display: none;
  }
}
