.section__aboutbanner {
  padding-top: 150px;
}

.about-banner-heading {
  position: relative;
}

.about-banner-heading::before {
  background: url("../../public/images/shadow-effect.png") no-repeat;

  background-size: auto;

  height: 500px;
  width: 400px;
  content: "";
  position: absolute;
  left: -150px;
  background-size: 100%;

  top: -100px;
}

.aboutbanner-ourmission {
  background: #16141e;
  margin-top: -200px;
  position: relative;
  padding: 50px 50px 0 0;
}

.aboutbanner-ourmission h3 {
  font-size: 1.5rem;
}

.ov-right-card {
  border: 1px solid #4c4660;
  padding: 50px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .teams__img img {
    max-width: 75%;
    margin: auto;

    display: block;
  }

  .ov-right-card {
    padding: 30px;
  }

  .aboutbanner-ourmission {
    padding-right: 0;
  }
}
/* ========::   owl__about :: ======== */

.owl__about .owl-dots {
  display: none;
}
.owl__about .owl-nav .owl-prev span,
.owl__about .owl-nav .owl-next span {
  color: #fdc229;
  font-size: 36px !important;
}

.owl__about button.owl-prev,
.owl__about button.owl-next {
  margin: 0 100px !important;
}
.owl__about button:hover {
  background: transparent !important;
}

.owl__about {
  position: relative;
}
.owl__about::before {
  background-size: auto;
  background-size: auto;
  height: 87%;
  width: 101%;
  content: "";
  position: absolute;
  left: -30px;
  background-size: 100%;
  z-index: 99999;
  top: 0px;
  background: -webkit-linear-gradient(to right, #16141e 14%, transparent 78%);
  background: linear-gradient(to right, #16141e 14%, transparent 78%);
}
@media (max-width: 767px) {
  .owl__about button.owl-prev,
  .owl__about button.owl-next {
    margin: 0 50px !important;
  }
}
/* ========::  owl__about  :: ======== */
