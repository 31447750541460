/* ========:: banner Owl start :: ======== */

.owl__banner .owl-carousel .owl-stage-outer {
  height: 100vh;
}

.owl__banner .layer_section {
  position: absolute;
  top: 17%;
  width: 100%;
}

.owl__banner .item {
  height: 100vh;
}

.owl__banner .sl_height {
  height: 80vh;
  padding-top: 40px;
  padding-bottom: 40px;
}

.owl__banner .owl-dots {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 11%;
  width: 100%;
  right: 9.5%;
}



.owl__banner .owl-dots button.active span {
  background: #fdc229 !important;

  position: relative;
}

.owl__banner .line_parent {
  height: 2px;
  background: #fff;
  width: 150px;
  display: inline-block;
  margin-left: 22px;
  position: relative;
  top: -6px;
}

.owl__banner .line_child {
  height: 6px;
  display: inline-block;
  background: #fdc229;
  position: absolute;
  left: 0px;
  top: -2px;
  transition: all 4s linear 0s;
  animation-duration: 4s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
}

.owl__banner .owl-theme .owl-dots .owl-dot.active span,
.owl__banner .owl-theme .owl-dots .owl-dot:hover span {
  background: #fdc229 !important;
}

.owl__banner .section_wise h4 {
  font-size: 25px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}

@keyframes changewidth {
  from {
    width: 0px;
  }

  to {
    width: 150px;
  }
}

@media (max-width: 767px) {
  .owl__banner .title {

    margin-bottom: 50px;
  }

  .owl__banner .sl_height {
    padding-top: 30%;
    height: 270px;
  }

  .owl__banner.section_wise h4,
  .owl__banner .section_wise p {
    font-size: 0.875rem;
  }

  .owl__banner .layer_section {
    top: 30%;
  }

  .owl__banner .owl-dots {
    justify-content: center;

    bottom: 5%;
    right: 0;
  }

  .owl__banner .section_wise h4 {
    font-size: 0.875rem;
}
}
/* ========:: End banner Owl  :: ======== */

/* ========:: 02 Foundation section :: ======== */

.yellow-heading {
  color: #fdc229;
  font-size: 21px;
  font-weight: 600;
}

.yellow-heading::after {
  display: inline-block;
  content: "";
  border-top: 0.3rem solid #fdc229;
  width: 4rem;
  margin: -11px 2rem;
  transform: translateY(-1rem);
}


/* ========:: 02 End Foundation section :: ======== */

/* ========:: 03 founders section :: ======== */
.our-founders p {
  color: #ffffff;
  font-style: italic;
}
.founder-name p {
  margin: 0;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 0px;
  font-style: inherit !important;
}

.founder-name span {
  font-weight: 400;
  color: grey;
  font-size: 18px;
}
.section-title-sec-k2 {
  background-color: #39334b8c;
  padding: 40px;
}

.tick1 {
  display: inline-block;
  content: "";
  border-left: 0.4rem solid #f8f8f8;
  margin: -13px 0rem;
  transform: translateY(-2rem);
  height: 17px;
  position: absolute;
}

.tick2 {
  display: inline-block;
  content: "";
  border-left: 0.4rem solid #f8f8f8;
  margin: -13px 11px;
  transform: translateY(-2rem);
  height: 17px;
  position: absolute;
}

.founder-sec {
  display: inline-flex;
}

.founder-name {
  margin: auto 22px;
}

.founder-sec img {
  border: 1px solid grey;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 100px;
}

/* ========:: 03 END founders section :: ======== */
/* ========:: 04 venture section :: ======== */

.upper-venture-one p {
  font-size: 1rem;
  color: grey;
}

.section-venture-k2-image {
  background-color: #211e2b;
  padding: 30px;
}

.venture-img {
  padding: 25px 10px;
  display: flex;
  justify-content: center;
}

.white_pencil {
  filter: grayscale(1);
  opacity: 0.7;
  width: 144px;
  position: relative;
  top: 16px;
}

.chilli_project {
  filter: invert(1);
  opacity: 0.65;
  width: 155px;
}

/* ========:: 04 END venture section :: ======== */

/* ========:: 05 about section :: ======== */

.sec-title-para {
    font-size: 16px;
    color: grey;
    width: 93%;
}

@media (max-width:767px){

 
    .section-title  br{ display: none;}
}