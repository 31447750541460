html {
  font-size: 16px;
}

body {
  font-family: "Work Sans", sans-serif;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  background-color: #16141e;
}

h1,
h2,
h4,
h5,
h6 {
  font-weight: 700;
}
h1,
.h1 {
  font-size:3.625rem;
}
h2,
.h2 {
  font-size: 2.25rem;
}

h3,
.h3 {
  font-size: 2rem;    font-weight: 500; 
}

h4,
.h4 {
  font-size: 1.75rem;
}

h5,
.h5 {
  font-size: 22px;
}
.fw-500{ font-weight:500;}
img {
  max-width: 100%;
  height: auto;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

a {
  color: #111;
  text-decoration: underline;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
.primary_color{color: #FDC229; }
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.f16 {
  font-size: 1rem;
}

.f20 {
  font-size: 1.25rem;
}

.mshow {
  display: none;
}


.section {
  padding: 75px 0;
  position: relative;
  background-color: #16141e;
}



.theme_btn {
  background: #211E2B;
  border: 1px solid #4C4660;
  padding: 12px 24px 14px 24px;
  display: inline-block;
  margin-top: 50px;
  color: #fff;
  font-weight: 600;
  border-radius: 0;
}

.theme_btn i {
  margin-left: 10px;
  position: relative;
  transform: rotate(-35deg);
}



.theme_btn:hover {
  color: #fff;
  background: #000;
}
.mb-100{ margin-bottom:100px}

@media (min-width:991px) {


  .wrapper {
    margin-bottom: 500px;
  }

}






@media (min-width: 1600px) {

  .container {
    max-width: 1500px !important;
  }
}







@media (max-width: 767px) {
  div#navbarSupportedContent {
    background: #16141e;
}
  .mb-100{ margin-bottom:50px}
  .mhide {
    display: none !important;
  }

  .mshow {
    display: block !important;
  }

  .section {
    padding: 50px 0;
  }

  .container, .container-fluid  {
    padding: 0 25px;
  }
  h1, .h1{  
  font-size: 1.875rem;}

  h2, .h2 {
    font-size: 1.75rem;
} 
h3,
.h3 {
  font-size: 1.5rem;
}
h5, .h5 {
  font-size: 1.125rem;
}
}