.scroll_wrapper a {
  padding-right: 37px;
  z-index: 1;
  position: relative;
  background: #16141e;
}.line {
  position: absolute;
  content: "";
  background: #fff;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 13px;
  left: 0px;
}
.scroll_wrapper 
a:hover {
  color: #fdc229 !Important;
}



@media (min-width:992px){ 

  .section__cards .container-fluid.px-md-5 .row:nth-child(even) .col-md-6 {
    
      order: 1 !important;
  }
  } 

@media(max-width:767px){

  .pcard__content{ margin-top: 50px;}
}