


.site_header {
    position: absolute;
    width: 100%;
    z-index: 11;
}


.site_header .navbar-nav li {
    margin-left: 15px; opacity: .78;
}

.site_header .navbar-nav li:hover{opacity:1;}


.navbar-brand img {
    max-width: 50px
  }
  
